body,html{
    overflow-x: hidden;    
}
.bg-dark{
    background-color:$black-light!important;
}
.container-fluid{
    max-width: 1400px;
}
@media (min-width: 1200px){
    .container {
        max-width: 1250px;
    }
}

.btn{
    &:focus,&:active{
        outline: none;
        box-shadow: none;
    }
}

#btn-play{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 42%;
    width: 150px;
    height: 75px;
    opacity: 0;
}
.whatsapp-btn{
    position: fixed;
    right:2%;
    bottom:2%;
    transition: .3s all;
    a{
        transition: .3s all;
        img{
            width:60px;
            height:60px;
            transition: .3s all;
        }
        &:hover{
            img{
                transform: scale(1.05);
            }
        }
    }
}

#nav{
    background-color: transparent;
    box-shadow: 0 4px 6px -4px transparent;
    transition: .3s all;
    padding-top:10px;
    @media screen and (max-width: 600px) {
        padding:5px;
        width:100%;
    }
    .logo{
        width:150px;
        height:auto;
        opacity: 0;
        transition: .3s all;
        @media screen and (max-width: 600px) {            
            width:140px;
            padding:5px 10px 0px;
        }
        &.desktop{
            @media screen and (max-width: 600px) {
                display: none;
            }
            @media screen and (min-width: 600px) {
                display: block;
            }
        }
        &.mobile{
            height:40px;
            width:auto;            
            @media screen and (max-width: 600px) {
                display: block;
                &.notscrolled{
                    display: block;
                }
                &.scrolled{
                    display: none;
                }
            }
            @media screen and (min-width: 600px) {
                display: none;
            }
        }        
    }
    .nav{
        .nav-item{
            .nav-link{
                color:$black-light;
                position: relative;
                transition: .3s all;
                font-family: 'Campton light';
                font-weight: 600;
                &.active{      
                    color:$orange;          
                    &:before{                    
                        width:50%;                    
                    }                
                }
                &:active,&:focus{
                    color:$orange;
                }       
                &:before{
                    content: '';
                    width:0%;
                    height:2px;
                    position: absolute;
                    bottom:0px;
                    right:0;
                    left:0;
                    display: block;
                    margin:0 auto;
                    background-color: $orange;   
                    transition: .3s all;
                }        
                &:after{
                    content:'';
                    width:1px;
                    height:15px;
                    position: absolute;
                    top:15px;
                    right:0;
                    background-color: $orange;                                                                                
                }
                
            }
            &:last-child{
                .nav-link{
                    &:after{
                        display: none;
                    }
                }
            }     
            &:hover{
               .nav-link{
                &:before{                    
                    width:50%;                    
                }
               }
            }
        }
    }
    .menu-btn{
        background-color: transparent;
        border:0;
        box-shadow: none;
        transition: .3s all;
        @media screen and (max-width: 600px) {
            position: absolute;
            right:0;
        }
        .burger-btn-white{
            display:block;
            transition: .3s all;
            @media screen and (max-width: 992px) {                
                padding:3px;
                border-radius: 3px;
            }
        }
        .burger-btn-blue{
            display:none;
            transition: .3s all;
            @media screen and (max-width: 600px) {                
                padding:3px;
                border-radius: 3px;
            }
        }
        img{
            width:30px;
            @media screen and (max-width: 600px) {
                width: 30px;
                margin-right: 5px;
                margin-bottom: 5px;
                margin-top:5px;
            }
        }
        &:hover{
            background-color: transparent;
            border:0;
            box-shadow: none;
        }
        &:focus,&:active{
            box-shadow: none;
            border:0;
            outline: none;
        }
    }
  
    &.scrolled{
        padding-top:0;    
        background-color: white;
        box-shadow: 0 4px 6px -4px #1f1f1f;      
        .navbar{
            .logo{
                opacity: 1;
            }
        }
       @media screen and (max-width:600px){
        .notscrolled{
            display: none!important;
        }
        .mobile.scrolled{
            display: block!important;
        }  
       }
        .menu-btn{
            .burger-btn-white{
                display:none;
                @media screen and (max-width:600px){
                    display: block;
                }
            }
            .burger-btn-blue{
                display:block;
            }            
        }            
    }
}


.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color:white;    
    overflow-x: hidden;
    transition: 0.7s;
    display:flex;
    .logo-overlay{
        position: absolute;
        top: 5%;
        left: 5%;
        width: 135px;
    }

    
    
}
  
.overlay-content {
position: relative;    
width: 100%;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
align-content: center;
justify-content: center;    
}

.overlay a {
padding: 8px;
text-decoration: none;
font-size: 36px;
color:$black-light;
display: block;
transition: 0.3s;
font-family: "Campton medium";
position: relative;
&.active,&:active{      
    color:$orange;          
    &:before{                    
        width:50%;                    
    }                
}
&:before{
    content: '';
    width:0%;
    height:2px;
    position: absolute;
    bottom:0px;
    right:0;
    left:0;
    display: block;
    margin:0 auto;
    background-color: $green;   
    transition: .3s all;
}  
@media screen and (max-width:1370px) {
    font-size: 30px;   
}
}
  
.overlay a:hover, .overlay a:focus {
color: white;
}

.overlay .closebtn {
position: absolute;
top: 20px;
right: 45px;
font-size: 60px;
display: flex;
z-index: 10000;
color:$orange;
}

@media screen and (max-width: 450px) {
.overlay a {font-size: 30px}
.overlay .closebtn {
font-size: 40px;
top: 10px;
right: 25px;
}
}

#inicio{
    position: absolute;
    top:0;
}

//banner
#banner{
    width: 100%;
    overflow: hidden;
    position: relative;
    .bg-banner{
        background-size: cover;
        min-height:760px;
        background-repeat: no-repeat;
        width: 100%;
        overflow: hidden;
        @media screen and (max-width:600px) {
            min-height: 650px;
        }
        .container{
            display: flex;
            position: relative;            
            .contenido-banner{
                margin-top:23%;
                margin-left:10%;                
                z-index: 10;
                @media screen and (max-width:600px) {
                    margin-top:20%;
                    margin-left:8%;
                }
                .contents{
                    img{
                        max-width: 150px;
                        margin-top:-85px;
                    }
                    @media screen and (max-width:600px) {
                        img{
                            margin-top:-50px;
                            max-width: 130px;
                        }
                    }
                    h1{
                        font-weight: 400;
                        font-family: "Campton medium";  
                        margin-top:15px;  
                        @media screen and (max-width:600px) {
                            font-size: 20px;
                            padding-right:50px;
                        }
                        b{
                            font-family: "Campton medium";
                            color:$orange;
                        }
                    }
                    .btn-banner{
                        
                        @media screen and (max-width:600px) {
                            display: flex;
                            flex-direction: column;
                        }
                        .btn-orange{
                            color:white;
                            background-color: $orange;
                            border-radius: 0;
                            margin-right: 15px;
                            width:180px;
                            @media screen and (max-width:600px) {
                                width:150px;
                                font-size: 15px;
                                margin-bottom:25px;
                            }
                        }
                        .btn-orange-outline{
                            color:$orange;
                            background-color: transparent;
                            border:2px solid $orange;
                            border-radius: 0;
                            width:180px;
                            @media screen and (max-width:600px) {
                                width:150px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }                
        }
        .guy{
            position: absolute;
            right:10%;
            bottom:-25%;
            max-width:500px;
            height:auto;
            display: block;
            // -webkit-filter: drop-shadow(12px 45px 23px #222222a3 ); 
            // filter: drop-shadow(12px 45px 23px #222222a3);
            z-index: 3;
            @media screen and (min-width:950px)  and (max-width:1090px) {
                max-width:400px;
            }
            @media screen and (min-width:600px)  and (max-width:950px) {
                max-width:330px;
            }
            @media screen and (max-width:600px) {
                max-width: 300px;
                right:-17%;
                bottom:-10%;
            }
        }
        &:before{
            content:'';
            width:5px;
            height:480px;
            position: absolute;
            display: block;
            background-color: $orange;
            top:0;
            left:12%;
            @media screen and (max-width:600px) {
                left:6%;
                height:350px;
            }
        }
        &:after{
            content:'';
            width:5px;
            height:480px;
            position: absolute;
            display: block;
            background-color: $orange;
            bottom:0;
            right:12%;
            z-index: 1;
            @media screen and (max-width:600px) {
                right:6%;
                height:370px;
            }
        }
    }
}


.line{
    width:20px;
    height:5px;
    display: block;    
    margin: 0px 0px 15px;
    &.green{
        background-color: $green;
    }
    &.orange{
        background-color: $orange;
    }
    
}


.navbar-toggler{
    border:0;
    outline: none;
    &.open{
        .navbar-toggler-icon{
            z-index: 1000;
            .white{
                display: block;
                z-index: 1001;
                position: relative;
                top:-20px;
            }
        }
    }
    @media screen and (max-width:600px) {
        margin-left:auto;
    }
    &:focus,&:active{
        outline: hidden;
    }

}

.navbar-toggler-icon{    
   &.open{
    .blue{
        display: block;
    }
    .white{
        display: none;
    }
   }
}

@media screen and (max-width:600px) {
    .navbar{
        padding:0;
        justify-content: flex-start;
        .navbar-toggler-icon{
            margin-top:8px;
            .white{
                display: none;
            }
            &.open{
                .blue{
                    display: none;
                }
                .white{
                    display: block;
                }
            }
        }
        .navbar-collapse{
            &.show{
                background-color: $blue-light;
                color:white;
                margin-top:-50px;
                .navbar-nav{
                    height:100vh;
                    justify-content: center;
                    .nav-item{
                        text-align: center;
                        .nav-link{
                            color:white!important;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
}
.alert{
    display: none;
    color:white;
}

.icons{
    width:24px;
    height:24px;
}

.logo-nav{
    width: 100px;
    height: auto;
    padding: 10px 10px 10px 20px;
    @media screen and (max-width:600px){
        width: 80px;
        height: auto;        
        position: absolute;
        left: 0;
        right: 10px;
        top: 5px;
        display: block;
        margin: 0 auto;
    }
}

#nosotros{
    margin:5%;
    @media screen and (max-width:600px){
        margin:0;
        .container{
            padding-left:0;
            padding-right:0;
        }
    }
    .embed-responsive{
        max-width: 800px;
        height:auto;
        margin: auto;
    }
    .nosotros-img{
        max-width:450px;
        margin-left:50px;
        @media screen and (max-width:600px){
            max-width: 100%;
            margin-left:0px;            
        }
    }
    .letters-nosotros{
        margin-top:5%;
        padding-left:70px;
        @media screen and (max-width:600px){
            padding-left:25px;
        }
        h2{
            position: relative;            
            padding-left:17px;
            @media screen and (max-width:600px){
                font-size: 24px;
                padding-top:3px;
                padding-left:15px;
                left:23px;
            }
            &:before{
                content:'';
                background-color: $orange;
                width:8px;
                height:60px;
                display: block;
                position: absolute;
                left:1px;
                top:8px;
                @media screen and (max-width:600px){
                    height:46px;
                    width:4px;
                }
            }
        }
        p{            
            line-height: 24px;
            color:$black-light;
            max-width: 490px;            
            font-weight: 600;
            margin-top:15px;
            font-size: 20px;
            text-align: justify;
            display: flex;
            justify-content: end;
            @media screen and (max-width:600px){
                padding-right: 30px;
                font-size: 18px;
                padding-left:20px;
            }
        }
    }
}

#servicios{
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;    
    padding:30px 0px;
    @media screen and (max-width:600px){
        height:650px;
    }
    .multiple-items{
        transition: .3s all;
        @media screen and (min-width:1300px){
            min-height:490px;
        }
        @media screen and (max-width:600px){
            height:440px;
        }
        .yellow-content{
            background-color: $yellow;
            text-align: center;            
            padding:15px;
            margin:auto;
            margin-left:25px;
            margin-right:25px;
            transition: .3s all;
            opacity: 0;
            @media screen and (max-width:600px){
                opacity: 1;
                width: 100%;
                min-width:280px;
            }
            img{
                margin:0 auto;
                display:block;
                padding:14px;
                width:100px;
                height:auto;
            }
            h3{
                font-size: 22px;
                line-height: 20px;
                color:$black-light;
                padding:10px 0px;
            }
            p{
                color:$black-light;
                font-size: 17px;
                position: relative;                
                max-height:0px;                
                transition: .3s all;
                padding-top: 10px;
                overflow: hidden;
                &:after{
                    content:'';
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    width:25px;
                    height:5px;
                    background-color: $orange;                    
                    margin:auto;
                }                
            }
        }
      
        .slick-slide{
            position: relative;    
            margin-top:1%;
            top:0;
            min-height: 280px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .slick-current{
            transition: .3s all;
            margin-top:0;
            top:0;
            p{
                max-height: 100%;
                opacity: 1;
            }
        }
        .slick-active{
            opacity: 1;           
        }
        .slick-prev:before, .slick-next:before{
            filter:brightness(10) grayscale(1);
        }
        .slick-prev{
            @media screen and (max-width:600px){
                left:-10px;
            }
        }
        .slick-next{
            @media screen and (max-width:600px){
                right:-6px;
            }
        }
    }
}

#beneficios{
    .information{
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        img{
            height:80px;
            margin:auto;
            padding: 10px;
        }
        h2{
            color:$blue;
            text-align: center;
            padding: 15px 0px 0px;
        }
        p{
            text-align: center;
            padding: 5px 15px;
            color:$blue;
            line-height: 18px;
        }
    }
}


.clientes-tabs{
    display: flex;
    justify-content: center;
    @media screen and (max-width:993px){
        justify-content:start;
        flex-direction: row;        
        height:95px;
        max-height:95px;
        display: flex;
        width: 100%;
        overflow-x: auto;
        overflow-y:hidden;
        -webkit-overflow-scrolling: touch;        
        flex-wrap: nowrap;
    }
    .nav-item{
        width:12.5%;
        @media screen and (max-width:993px){
            width:150px;
            position: relative;      
            white-space: nowrap;      
        }
        .nav-link{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;    
            background-color: white;       
            &:after{
                content:'';
                width:2px;
                height:50px;
                background-color: $orange;
                position: absolute;
                right:0;
                display: flex;
                justify-content: center;
                align-content: center;                
            }           
            p{
                color:$blue;
                font-size: 14px;
                margin-bottom: 0;
            }
            img{
                width:40px;
                height:auto;
                padding-top:10px;
                margin-bottom: 5px;
                &.white{
                    display: none;
                }
                &.blue{
                    display: block;
                }
            }
            &.active{
                background-color: $orange;   
                border-radius: 16px 16px 0px 0px;
                p{
                    color:white;
                }
               img{
                    &.white{
                        display: block;
                    }
                    &.blue{
                        display: none;
                    }
               }
            }
        }
        &:last-child{
           .nav-link{
            &:after{
                background-color: transparent;
            }
           }
        }
    }
}
.clientes-contenido{    
    border-top: 0px solid $orange;
    position: relative;
    top: -2px;
    box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.30);
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.30);
    border-radius: 0px 0px 15px 15px;
    min-height:215px;  
    margin:0.5px;
    max-height:250px;
    overflow: hidden;
    .clientes-slide{
        position: relative;
        top:50px;
        margin:5px 40px;
        
        .client-box{
            display: flex;
            justify-content: center;
            align-content: center;
            width:250px;
            height:90px;
            padding:5px 15px;
            position: relative;                     
            img{
                max-width: 90%;
                height: auto;      
                padding-left:5px;          
            }
            &.slick-active{
                &:before{
                    content:'';
                    width:3px;
                    height:70px;
                    background-color: $orange;
                    position: absolute;
                    right:-5px;
                    top:15px;
                    display: flex;
                    justify-content: center;
                    align-content: center; 
                }
                &:nth-child(2){
                    &:before{
                        background-color: transparent;
                    }
                }
            }
            &.line-right{
                &.slick-active{
                    &:nth-child(2){
                        &:before{
                            background-color: $orange;
                        }
                    }
                }
            }
        }
    }
}

.slick-next:before{
    content:''!important;
    background-image: url('/assets/img/arrow-orange.png');
    background-repeat: no-repeat;
    background-size: contain;
    width:25px;
    height:25px;
    display:block;
    transform: rotate(180deg);
}
.slick-prev:before{
    content:''!important;
    background-image: url('/assets/img/arrow-orange.png');
    background-repeat: no-repeat;
    background-size: contain;
    width:25px;
    height:25px;
    display:block;
}

#aliados{
  padding:0px 9%;  
    .allies-slide{        
        margin:15px 0px;    
        .allie-box{
            display: flex;
            justify-content: center;
            align-content: center;
            width:220px;
            height:90px;
            padding:5px 20px;
            position: relative;               
            img{
                max-width: 80%;
                height: auto;                
            }
        }
    }
}

#testimonios{
    position: relative;        
    margin-top:200px;
    .testimonios-slide{        
        margin:15px auto 15px;    
        position: relative;        
        max-width: 90%;    
        top:-200px; 
        margin-bottom:-130px;               
        .testimonio-box{
            padding:20px;     
            .info{
                border-radius: 10px;       
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;                
                height:400px;
                background-color: $orange;
                color:white;
                padding:25px 40px 15px;
                @media screen and (max-width:600px){
                    padding:25px 40px 25px;
                }
            p{
                color:white;
                font-family: "Campton medium"!important;
                font-weight: 400;
                font-size: 17px;
                text-align: center;
                @media screen and (max-width:500px){
                    font-size: 14px;
                }
            }
            span{
                font-family: "Campton medium"!important;
                font-weight: 400;
                font-size: 20px;
                text-align: center;
                color:$yellow;
                line-height: 24px;
                padding-top:15px;
                @media screen and (max-width:500px){
                    font-size:16px;
                }
            }
            img{
                max-width: 80%;
                height: auto;                
            }
            }        
        }
    }
    .slick-dots{
        bottom:25px;
        li{
            button{
                background-color: white;
                height:10px!important;
                width:10px!important;
                border-radius: 20px;
                &:before{
                    opacity: 0;
                    color:white;
                }
            }
            &.slick-active{
                button{
                    width:20px!important;
                }
            }
        }
    }
}

.webinars-slide{
    .webinar-box{
        position: relative;   
        padding:20px;    
        .info{
            p{
                padding:5px 21%;
            }
        }         
    }
    .slick-prev:before, .slick-next:before{
        filter:brightness(10) grayscale(1);
    }
}

#blog{
    background-color: $yellow;
    padding:5% 2%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    @media screen and (max-width:500px){
        padding:40% 6%;
    }
    .content{
        h5{
            font-size: 32px;
            max-width: 650px;            
            text-align: center;   
            @media screen and (max-width:500px){
                font-size: 28px;
                position: relative;
                &:before{
                    content:'';
                    background-color: $orange;
                    width:40px;
                    height:10px;
                    position: absolute;
                    left:0;
                    right:0;
                    top:-20px;
                    display: block;
                    margin:auto;
                }
            }         
        }
        .btn-orange-outline{
            color:$orange;
            background-color: transparent;
            border:2px solid $orange;
            border-radius: 0;
            width:220px;
            font-weight: 600;            
            display:block;
            font-family: 'Campton medium';
            margin:30px auto 0;
            padding:3px;
        }
    }
}

#contacto{
    background-color: $black-light;
    padding: 5%;
    @media screen and (max-width:500px){
        padding:15% 3%;
    }
    .form{
        background-color: white;
        border-radius: 15px;
        box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.9);
        padding:5px 70px;
        min-height: 530px;
        @media screen and (max-width:993px){
            padding:5px 20px;
            margin-bottom:30px;
        }
        .form-format{            
            label{
                text-align: center;
                margin:auto;
                font-family: "Campton light";
                font-weight: 600;
                display: block;
            }
            .learning-form-input{
                border:0;
                border-bottom: 1px solid black;
                padding: 0px 10px;
                border-radius: 0;
                &:active,&:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
            textarea{
                resize: none;
            }
        }
        .send-mail{
            background-color: $yellow;
            color:#464646;
            width:220px;
            font-weight: 600;    
            font-family: 'Campton light';
            padding:3px;
            display: block;            
            margin:30px auto;
            @media screen and (max-width:600px){
                width:170px;
            }
        }
    }
    .agenda{
        background-color: $orange;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        padding:3% 15%;
        min-height: 530px;
        border-radius: 15px;
        box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.9);
        max-width: 85%;
        margin:auto;
        @media screen and (min-width:500px)  and (max-width:993px){
            max-width:100%;
        }
        @media screen and (max-width:500px){
            max-width:100%;
        }
        p{
            color:white;            
            text-align: center;
            margin-bottom: 3px;
            font-size: 20px;
            line-height: 20px;
        }
        .btn-white-orange{
            background-color: white;
            color:$orange;
            width:220px;
            font-weight: 600;            
            display:block;
            font-family: 'Campton light';
            font-size: 20px;
            margin:30px auto 0;
            border-radius: 0;
        }
    }
}


// secciones scroll
#seccion_nosotros{
    position: relative;
    bottom:180px;
    @media screen and (max-width:500px){
        bottom:100px
    }
}

#seccion_servicios{
    position: relative;
    bottom:100px;
    @media screen and (min-width:1700px){
        bottom:160px;
    }

    @media screen and (max-width:500px){
        bottom:50px
    }
}

#seccion_beneficios{
    position: relative;
    bottom:50px;
    @media screen and (min-width:1700px){
        bottom:100px;
    }
}

#seccion_clientes{
    position: relative;
    bottom:50px;
}
#seccion_testimonios{
    position: relative;
    bottom:50px;
    @media screen and (max-width:500px){
        bottom:60px
    }
}

#seccion_webinars{
    position: relative;
    bottom:120px;
}

.clientes-tabs{
    &::-webkit-scrollbar {
        width: 13px;
        height: 8px;
        position: relative;
        top:15px
       }
       &::-webkit-scrollbar-thumb {
        background: linear-gradient(13deg, #ffdb00 14%,#ffdb00 64%);
        border-radius: 0px;
       }
       &::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%);
       }
       &::-webkit-scrollbar-track{
        background: #ffffff;
        border-radius: 0px;
        box-shadow: inset 7px 10px 12px #f0f0f0;
       }
}