//  Table of Contents:
//
//   1. Global
//   2. Grid breakpoints
//   3. Base Typography
//   4. Typography Helpers
//   5. Abide
//   6. Accordion
//   7. Accordion Menu
//   8. ... 
// 1. Global
// ---------


/*!
 * Primary color settings
 */

$blue-light: #39adc1;
$blue-light-2:#7dd6e4;
$blue-dark: #0c202f;
$blue:#3f3f70;
$red: #de3f45;
$black: #303030;
$white: #fff;
$grey:#e2e2e2;
$grey-dark:#abacaf;
$grey-light:#efeeee;
$grey-x-light:#f1f1f1;
$grey-medium:#8c8c8c;
$grey-form:#C8C8C8;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: ( xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1140px) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);





// colores
$orange:#cd390d;
$orange-dark:#b43711;
$black-light:#353535;
$blue:#0074ba;
$blue-light:#00acf5;
$green:#00e79b;
$yellow:#ffdb00;
//fonts

$font_path: '/assets/fonts/';

@font-face {
    font-family: "YanoneKaffeesatz-Bold";    
    src: url($font_path+'YanoneKaffeesatz-Bold.ttf') format("truetype");
    font-style: normal;
    font-weight:bold;
}
@font-face {
    font-family: "YanoneKaffeesatz-Regular";    
    src: url($font_path+'YanoneKaffeesatz-Regular.ttf') format("truetype");
    font-style: normal;
    font-weight:400;
}

@font-face {
    font-family: "campton-bold";
    src: url($font_path+'Campton-Bold.otf') format("opentype");
    src: url($font_path+'Campton-Bold.ttf') format("truetype");
    font-style: normal;
    font-weight:bold;
}

@font-face {
    font-family: "Campton light";
    src: url($font_path+'Campton-Light.otf') format("opentype");
    src: url($font_path+'Campton-Light.ttf') format("truetype");
}
@font-face {
    font-family: "Campton medium";
    src: url($font_path+'Campton-Medium.otf') format("opentype");
}
/*bold version*/
@font-face {
	font-family: 'camptonBold';	
    src: url($font_path+'Campton-Bold.eot'); 
	src: 
		local('Campton Bold'),
		local('Campton-Bold'),
		url($font_path+'Campton-Bold.otf') 
		format('opentype');
	font-weight: bold;
}

@font-face {
    font-family: 'Moderat';
    src: local('Moderat  Regular'), local('Moderat--Regular'),
        url($font_path+'Moderat-Regular.woff2') format('woff2'),
        url($font_path+'Moderat-Regular.woff') format('woff'),
        url($font_path+'Moderat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}


html {
    scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,h6{
    font-family: "Campton medium"!important;    
}
.title{
    color:$black-light;
    font-size: 2rem;
    text-align: center;
    padding: 30px 0px;
    font-family: 'Campton medium';
    font-weight: 800;
    position: relative;
    width: fit-content;
    margin:auto;
    &.title-white{
        color:white;
    }
    &:before{
        content:'';
        width:5px;
        height:25px;
        position: absolute;
        display: block;
        background-color: $orange;
        top:36px;  
        left:-10px;      
    }
    &.bottom-line{
        &:before{
            top:initial;
            bottom:20px;
            left:0;
            right:0;
            width:30px;
            height:8px;
            margin:auto;
        }
    }
    &.yellow-line{
        &:before{
            background-color: $yellow;
        }
    }
    &.blue-line{
        &:before{
            background-color: $blue;
        }
    }
}

p{
    font-family: 'Campton light';
    color:$black;
    font-weight: 600;
}

.medium-typo{
    font-family: "Campton medium"!important;    
}

.bold-typo{
    font-family: 'camptonBold'!important;    
    font-weight: 400;
}